import React from "react";
import Layout from "components/layout";
import PageHeader from "components/PageHeader";
import SEO from "components/seo";

function DataArchive() {
  return (
    <Layout>
      <SEO metadata={{ title : "Data Archive" }} />
      <PageHeader title="The Archive" desc="config.data_archive.description" />
      <p css={{
        fontSize: "2rem",
        margin: "4em auto",
        maxWidth: "40ch",
      }}>The Ukrainian Archive is updating this database. It will be available again soon. If you have any specific inquiries, please reach out to us at <a href="mailto:info@ukrainianarchive.org">info@ukrainianarchive.org</a>.</p>
    </Layout>
  );
}

export default DataArchive;
